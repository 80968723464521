<template>
  <div>
    <ca-header heading="Schäden" />
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        service="damage-reports"
        :base-query="baseQuery"
        @row-clicked="(item) => $router.push({name: 'DamageReportDetail', params: {reportId: item._id}})"
      />
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable/DataTable'
import formatDate from '../../filters/formatDate.js'
import CaHeader from '@/components/Layout/Header'
export default {
  name: 'DamageReportList',
  components: {
    DataTable,
    CaHeader
  },
  data: () => ({
    fields: [
      {
        label: 'Schaden-Nr.',
        key: 'SCHADEN.SNR_MAKLER'
      },
      {
        label: 'Schadendatum',
        key: 'SCHADEN.SDA',
        formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
        filter: {
          type: 'DATERANGE'
        }
      },
      {
        label: 'Angelegt am',
        key: 'createdAt',
        formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
        filter: {
          type: 'DATERANGE'
        }
      }
    ]
  }),
  computed: {
    baseQuery () {
      if (this.$route.params.userId === 'me') {
        return {
          'FIELDS.userId': this.$store.getters['auth/user'].user._id
        }
      } else {
        return {}
      }
    }
  }
}
</script>
